import React, {Component} from "react"
import SEO from "utils/Seo"
import Layout from "components/Layout/Layout"
import MoreTopics from "../../../components/Education/MoreTopics"
import styled from "styled-components";
import HeadingSpecial from "../../../components/shared/HeadingSpecial";
import {Container} from "reactstrap";
import CarouselSection from "../../../components/Education/Design/CarouselSection";
import {graphql, StaticQuery} from "gatsby";
import SnowFlakes from "../../../components/shared/SnowFlakes";

const pageSlug = "puppets"
const pageTitle = "Puppets"
const categorySlug = "design"
const categoryTitle = "Design"

const Content = styled.div`
    position: relative;
    
    text-align: center;
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                allPuppetsCarouselJson {
                    edges {
                        node {
                            copy
                            image {
                                childImageSharp {
                                    gatsbyImageData(width: 1048, quality: 100)
                                }
                            }
                        }
                    }
                }
            }
		`}
        render={data => (
            <Puppets data={data}/>
        )}
    />
)

class Puppets extends Component {
    constructor(props) {
        super(props);

        this.state = {
            clickDisabled: false
        }

        this.pageData = {
            pageName: pageSlug,
            siteName: "frozeneducation",
            country: "uk",
            region: "emea"
        }
    }

    componentDidMount() {
        // if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
        //     // Tealium
        //     window.utag.view(this.pageData)
        // }
    }

    resourcesClicked = (e, resource) => {
        if (this.state.clickDisabled){
            e.preventDefault()
            return;
        }

        if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
            console.log("Tag firing")
            // Tealium
            let linkData = {
                siteName: "frozeneducation",
                country: "uk",
                region: "emea",
                actionName: "download_materials",
                actionValue1: resource
            }
            window.utag.link(linkData)
            this.setState({
                clickDisabled: true
            }, () => {
                setTimeout(function () {
                        console.log('Now you can click again')
                        this.setState({
                            clickDisabled: false
                        });
                    }.bind(this), 500
                );
            })
        }
    }

    render() {

        return (
            <Layout categorySlug={categorySlug}
                    category={categoryTitle}
                    currentPage={pageTitle}
                    pageName={pageSlug}
                    mainCategorySlug="beyond-the-story"
                    enrichmentHeader>
                <SEO slug={pageSlug} title={pageTitle} description=""/>
                <SnowFlakes>
                <Content className="enrichment">
                        <HeadingSpecial lines={false} headingTag="h1"
                                        className="py-4 py-md-5 font--bigger"
                                        title={pageTitle}/>

                        {/*<Container fluid={true}>*/}
                        {/*    <div className="content">*/}
                        {/*        <h4 className="font--regular font-weight-bold">Teachers, take your experience further. <a*/}
                        {/*            onClick={(e)=>this.resourcesClicked(e, "puppets")}*/}
                        {/*            href="#" className="no-interstitial-check">Download&nbsp;this&nbsp;lesson&nbsp;plan</a> exploring the collaborative nature of making art.</h4>*/}
                        {/*    </div>*/}
                        {/*</Container>*/}
                        <Container fluid={true} className="no-padding-xs">
                            <CarouselSection
                                page="puppets"
                                carouselData={this.props.data.allPuppetsCarouselJson.edges}
                                altText="Photographs by Andrew Eccies."
                            />
                        </Container>
                </Content>
                    <div className="py-5">&nbsp;</div>
                </SnowFlakes>
                <MoreTopics inspiration adaptation/>
            </Layout>
        )
    }
}
export default Query