import React, {Component} from 'react';
import styled from 'styled-components'
import {media} from "utils/Media"
import Slider from 'react-slick'
import {graphql, StaticQuery} from "gatsby";
import {GatsbyImage, getImage} from "gatsby-plugin-image"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import arrowRight from '../../../images/carousel-arrow-right.png'
import arrowRightXs from '../../../images/arrow-right-xs.svg'

const Section = styled.div`
    margin: 0 auto;
    
    @media ${media.xl} {
        margin: 0 auto;
    }
    
    h5 {
        margin-top: 5rem;
        padding: 0 15px;
        
        @media ${media.sm} {
            padding: 0;
        }
    }
    
`

const PhotoSlider = styled(Slider)`
    position: relative;
    z-index: 2;
    margin: 0 auto;
    max-width: 580px;
    
    @media ${media.lg} {
        max-width: 800px;
    }
    
    @media ${media.xl} {
        max-width: 1048px;
    }
    
    h5 {
        margin-top: 0.5rem;
        font-size: 0.75rem;
        line-height: 1rem;
        margin-bottom: 0;
        
        @media ${media.sm} {
            margin-top: 2rem;
            font-size: 1rem;
            line-height: 1.375rem;
        }
    }
    
    a {
        text-decoration: underline;
    }
    
    .slick-track {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        justify-content: center;
    }
    
    .slick-slide{
        padding: 0;
        transition: all 0.3s ease;
        height: auto;
        
        &.slick-current,
        &.slick-center {
          opacity: 1 !important;
          
        }
        opacity: 0.2;
        
        &.slick-active {
            //opacity: 0.2;
        }
        
        &.slick-cloned {
            //margin-left: -25%;
        }
        
        .img {
            //display: block;
            //max-width: 100%;
            //height: auto;
        }
    }
    
    .photo-wrapper {
        position: relative;
        max-width: 640px;
        cursor: pointer;
        
        @media ${media.xl} {
            max-width: 1048px;
        }
        
    }
    
    .slick-dots {
        bottom: -25px;
        z-index: 9;
        
        @media ${media.md} {
            bottom: -35px;
        } 
        
        @media ${media.lg} {
            bottom: -50px;
        } 
    }
    
    .slick-dots li, .slick-dots li button {
        width: 10px;
        height: 10px;
        
        @media ${media.md} {
            width: 12px;
            height: 12px;
        }
        
        @media ${media.lg} {
            width: 20px;
            height: 20px;
        }
    }
    
    .slick-dots li button:before {
        color: ${props => props.theme.colors.turquoise2};
        opacity: 1;
        font-size: 10px;
        
        @media ${media.md} {
            font-size: 12px;
        }
        
        @media ${media.lg} {
            font-size: 20px;
        }
    }
    
    .slick-dots li.slick-active button:before {
        color: transparent;
        border: 3px solid #63DBE7;
        border-radius: 5px;
        top: 4px;
        left: 5px;
        width: 10px;
        height: 10px;
        
        @media ${media.md} {
            top: 3px;
            border-radius: 6px;
            width: 12px;
            height: 12px;
        }
        
        @media ${media.lg} {
            left: 0;
            top: -1px;
            border-radius: 10px;
            width: 20px;
            height: 20px;
        }
    }
    .slick-prev, .slick-next {
        width: 16px;
        height: 32px;
        bottom: -50px;
        top: initial;
        z-index: 10;
        
        @media ${media.md} {
             width: 50px;
            height: 50px;
            top: 175px;
            bottom: initial;
        }
        
        @media ${media.lg} {
            top: 50%;    
        } 
        
        &:focus {
            outline: 5px auto -webkit-focus-ring-color !important;
        }
    }
    
    .slick-next {
        right: 17px;
        bottom: -56px;
        
        @media ${media.md} {
            right: -80px;
            bottom: -50px;
        }
        
        &:before {
            content: '';
            background: url(${arrowRightXs}) no-repeat center center;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
            
             @media ${media.md} {
                background: url(${arrowRight}) no-repeat center center;
                background-size: contain;
             }
        }
    }
    
    .slick-prev {
        left: 20px;
        
        @media ${media.md} {
            left: -80px;
        }
        
        &:before {
            content: '';
            transform: rotate(180deg);
            background: url(${arrowRightXs}) no-repeat center center;
            background-size: contain;
            position: absolute;
            left: 0;
            top: 6px;
            width: 100%;
            height: 100%;
            
            @media ${media.md} {
                background: url(${arrowRight}) no-repeat center center;
                background-size: contain;
                top: 0;
             }
        }
    }
    
`

const Query = (props) => (
    <StaticQuery
        query={graphql`
            query {
                bgImage: file(relativePath: { eq: "EducationPage/menu-bg.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 1920, quality: 100, placeholder: NONE
                            formats: [AUTO, WEBP])
                    }
                }
            }
		`}
        render={data => (
            <CarouselSection
                data={data}
                carouselData={props.carouselData}
                altText={props.altText}
                page={props.page}
            />
        )}
    />
)

class CarouselSection extends Component {
    sendTealium = (currentSlide, slideCount, which) => {
        let sliderId

        if (which === 'right') {
            this.carousel.slickNext();
            sliderId = currentSlide + 1
            if (sliderId === slideCount) {
                sliderId = 0
            }
        } else {
            this.carousel.slickPrev();
            sliderId = currentSlide + -1

            if (sliderId < 0) {
                sliderId = slideCount - 1
            }
        }

        if (typeof window !== 'undefined' && typeof window.utag !== 'undefined') {
            // Tealium
            let linkData = {
                siteName: "frozeneducation",
                country: "uk",
                region: "emea",
                actionName: "navButtonClick",
                actionValue1: `${this.props.page} carousel ${sliderId}`
            }
            console.log(`${this.props.page} carousel ${sliderId}`)
            window.utag.link(linkData)
        }
    }

    render() {
        const SlickArrowLeft = ({currentSlide, slideCount, ...props}) => (
            <button
                {...props}
                className={
                    "slick-arrow slick-prev"
                }
                aria-hidden="false"
                aria-disabled={currentSlide === 0}
                type="button"
                tabIndex={0}
                onClick={() => this.sendTealium(currentSlide, slideCount, 'left')}
            >
                Previous
            </button>
        );
        const SlickArrowRight = ({currentSlide, slideCount, ...props}) => (
            <button
                {...props}
                className={
                    "slick-arrow slick-next"
                }
                aria-hidden="false"
                aria-disabled={currentSlide === slideCount - 1}
                type="button"
                tabIndex={0}
                onClick={() => this.sendTealium(currentSlide, slideCount, 'right')}
            >
                Previous
            </button>
        );
        const settings = {
            centerMode: true,
            slidesToShow: 1,
            centerPadding: '0',
            dots: true,
            arrows: true,
            prevArrow: <SlickArrowLeft/>,
            nextArrow: <SlickArrowRight/>,
            cssEase: 'linear',
            draggable: true,
            swipe: true,
            swipeToSlide: true,
            touchMove: true,
            accessibility: true,
            responsive: [
                {
                    breakpoint: 991,
                    settings: {
                        centerPadding: '0',
                    }
                }
            ]
        }

        const items = this.props.carouselData;
        const galleryItems = items.map(({node}, id) => {
            return (
                <div
                    className="photo-wrapper"
                    key={id}
                >
                    <GatsbyImage placeholder="none"
                        image={getImage(node.image)}
                        alt=""
                        title=""
                        className="img"
                    />

                    <h5 className="font--regular color--navy font-weight-bold narrow mx-auto" dangerouslySetInnerHTML={{__html: node.copy}}/>
                </div>
            )

        })
        return (
            <Section bgImage={this.props.bgImage}>
                <PhotoSlider
                    {...settings}
                    className="PhotoGallery"
                    ref={slider => (this.carousel = slider)}
                >
                    {galleryItems}
                </PhotoSlider>

                <h5 className="font--regular color--navy font-weight-bold narrow mx-auto">{this.props.altText}</h5>
            </Section>

        );
    }
}

export default Query;